<template>
	<div>
		<wd-navbar>
			<div slot="left" class="middle" @click="$goHome">
				<wd-icon name="arrow-left" />
			</div>
			<div>
				<span>综合能耗</span>
			</div>
		</wd-navbar>
		<div>
			<wd-drop-menu class="dropDown" v-if="dataList.length > 0">
				<wd-drop-menu-item v-for="(item,index) in dataList" :key="index" v-model="results[item[0].id]" @change="searchBar" :options="item" />
			</wd-drop-menu>
			<wd-search v-model="queryParams.equipmentName" cancel-txt="搜索" class="search" @cancel="searchBar"></wd-search>
			<div class="equipmentList">
				<wd-cell-group v-for="(item,index) in tableData" :key="index">
					<wd-cell class="cellList" style="font-size: 14px;" :is-link="true" :to="{name: 'powerChart', params: item}">
						<div slot="title" style="white-space: nowrap">
							名称：{{item.equipmentName || '--'}}
						</div>
						<div slot="label" style="font-size: 14px;">
							类型：{{item.equipmentType == '0' ? "电表" : "水表"}}
						</div>
						<div slot="default">
							<font style="color: #34d19d" v-if="item.status == '0'">在线</font>
							<font style="color: #999" v-else-if="item.status == '1'">离线</font>
						</div>
						<div slot="icon" style="display: flex; align-items: center; margin-right: 10px;">
							<img src="../../assets/img/ele.svg" style="width: 40px" v-if="item.equipmentType == '0'" />
							<img src="../../assets/img/water.svg" style="width: 40px" v-else />
						</div>
					</wd-cell>
				</wd-cell-group>
				<wd-infinite-load ref="loadmore" @loadmore="loadmore" v-if="showLoading" :loading="loading"></wd-infinite-load>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				showLoading: false,
				count: 15,
				loading: false,
				roleKey: null,
				loadingData: true,
				tableData: [],
				queryParams: {
					equipmentName: "",
					propertiesIds: null,
					pageNum: 1,
					pageSize: 15,
					total: 0,
				},
				propertiesList: [],
				results: {},
				dataList: []
			}
		},
		created() {
			this.roleKey = localStorage.getItem('roleKey')
			this.$http.get("/external/properties/list", { params: { roleKey: this.roleKey } }).then(res=>{
				if( res.code == 200 ) {
					this.propertiesList = res.rows
					this.dataList = []
					if( this.propertiesList && this.propertiesList.length > 0 ) {
						this.propertiesList.map((item,index)=>{
							let options = []
							options.push({label: item.propertiesName, value: "", id: item.propertiesId})
							this.$set(this.results, item.propertiesId, "")
							if( item.properties && item.properties.length > 0 ) {
								item.properties.map((child,ind)=>{
									let obj = {}
									obj.label = child.propertiesName
									obj.value = child.propertiesId
									options.push(obj)
								})
							}
							this.dataList.push(options)
						})
					}
				}
			})
			this.queryParams.roleKey = this.roleKey
			this.getList()
		},
		methods:{
			searchBar(){
				this.queryParams.pageNum = 1
				this.tableData = []
				this.getList()
			},
			getList(){
				let propertiesIds = this.handleSearch()
				if( propertiesIds ) {
					console.log(propertiesIds, "12312321")
					this.queryParams.propertiesIds = propertiesIds
				} else {
					this.queryParams.propertiesIds = null
				}
				this.showLoading = false
				this.$http.get("/external/equipment/equipment/list", { params: this.queryParams }).then(res=>{
					if( res.code == 200 ) {
						this.showLoading = true
						this.loadingData = true
						this.tableData = this.tableData.concat(res.rows)
						this.queryParams.total     = res.total
						if( this.tableData.length == res.total ) {
							this.showLoading = false
						}
					}
				})
			},
			handleSearch(){
				let result = []
				for( let key in this.results ) {
					if( this.results[key] && this.results[key] != '' ) {
						result.push(this.results[key])
					}
				}
				if( result.length > 0 ) {
					return result.join(',')
				} else {
					return null
				}
			},
			loadmore(){
				if( this.loadingData ) {
					this.loadingData = false
					this.queryParams.pageNum++
					setTimeout(()=>{
						this.getList()
					}, 1000)
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.dropDown{
		/deep/ .wd-drop-menu__list{
			width: 100vw;
			flex-wrap: wrap;
			.wd-drop-menu__item{
				flex: none;
				width: 50%;
			}
		}
	}
	.search{
		/deep/ .wd-search__cancel{
			font-size: 14px;
		}
	}
	.cellList{
		/deep/ .wd-cell__wrapper{
			align-items: center;
		}
	}
</style>
